.Home {
    font-family: 'Inter';
    color: aliceblue;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;

    &__error {
        color: red;
        padding-left: 30px;
        margin-top: 10px;
        display: none;
    }

    &__error.show {
        display: block;
    }

    &__left, &__right {
        width: 33%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: end
    } 
    


    &__center {
        width: 100%;
    }

    &__header {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;

        background: linear-gradient(90deg, #51C3FC 1.84%, #9C8DD7 50.43%, #DC5EB8 102.58%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin: 0 auto;
        width: 600px;
        text-align: center;
        margin-bottom: 12px;
    }

    &__body {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // border-style: solid;
        // border-color: aliceblue;
        // border-radius: 24px;
        // padding-top: 56px;
        // padding-bottom: 56px;
        // padding-left: 32px;
        // padding-right: 32px;
        padding: 3px;
        width: 600px;
        height: 500px;
        margin: 0 auto;
        background: linear-gradient(#50C5FB, #DC5FB8);
        border-radius: 24px;
    }

    &__body-container {
        padding-top: 56px;
        padding-bottom: 56px;
        width: 600px;
        height: 388px;
        margin: 0 auto;
        background-color: #000000;
        border-radius: 24px;
    }

    &__row {
        background: #1A1521;
        border-radius: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 16px;
        padding-bottom: 16px;
        // padding-left: 40px;
        // padding-right: 40px;
        margin-bottom: 8px;
        width: 536px;
        margin: 0 auto;
        margin-bottom: 8px;

        &__volume {
            // font-weight: 400;
            // font-size: 32px;
            // line-height: 39px;
            // text-transform: uppercase;

            &__input {
                font-weight: 400;
                font-size: 32px;
                line-height: 39px;
                text-transform: uppercase;
                border: none;
                background: #1A1521;
                color: white;
                width: 100%;
            }

            &__input:focus {
                outline: none;
            }
        }


        &__price {
            font-weight: 300;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;

            color: #B9B9B9;
        }

        &__symbol {
            width: 115px;
            height: 40px;
            background: #292330;
            border-radius: 24px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-right: 40px;
            &__name {
                padding-right: 10.5px;
            }

            &__icon {
                padding-left: 18px;
            }
        }

        &__value {
            padding-left: 40px;
        }

        &__balance {
            font-weight: 300;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
            margin-right: 40px;
            color: #B9B9B9;
        }
    }

    &__row-first {
        flex-direction: column;
        margin-bottom: 16px;
        
        &__data {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__arrow {
            height: 0;
            margin: 0 auto;
        }
    }

    &__row-last {
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        width: 536px;

        span {
            padding-left: 40px;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #DC58A7;
        border-radius: 24px;
        color: #000000;
    
        width: 258px;
        height: 64px;
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        cursor: pointer;
    }

    &__button.disabled {
        background: #4F1F3C;
        cursor: default;
    }

    @media (max-width:480px)  { 

        &__left {
            display: none;
        }

        &__right {
            width: 100%;
            justify-content: end;
        }

        &__body-container {
            width: 100%;
        }

        &__body {
            width: 95%;
        }

        &__header {
            font-weight: 700;
            font-size: 12px;
            width: 95%;
        }

        &__row {
            width: 95%;
            &__volume {
                font-weight: 400;
                font-size: 18px;
            }
    
            &__price {
                font-weight: 300;
                font-size: 12px;
            }

            &__balance {
                font-weight: 300;
                font-size: 12px;
            }
        }

        &__row-last {
            font-weight: 300;
            font-size: 12px;
        }

        &__button {
            width: 123px;
            height: 41px;
            font-weight: 600;
            font-size: 14px;
            border-radius: 16px;
        }

    }

    .sifuIcon {
        width: 20px;
        height: 20px;
    }
    .usdtIcon {
        width: 20px;
        height: 20px;
    }
    .uwuIcon {
        width: 20px;
        height: 20px;
    }
}

@media (max-width:480px)  {
    .Home {
        align-items: start;
        flex-direction: column;
    }
}