.App {
  max-width: 1440px;
  min-height: 100%;
  height: 100%;
  margin: 0 auto;

}

.LogoContainer {
  width: 477px;
  height: 477px;
  left: 984px;
  // top: 588px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(81, 196, 251, 0.7) 0%, rgba(216, 100, 193, 0.7) 100%);
  filter: blur(271px);
  position: absolute;
  left: 63%;
  bottom: 0%;
}

.Logo {
  background-image: url('./images/logo.svg');
  width: 320px;
  height: 350px;
  margin-bottom: 0;
  // position: absolute;
  // left: 70%;
  // bottom: 0;
}

@media (max-width:480px) {
  .Logo {
    
  }
  .LogoContainer {
    width: 150px;
    height: 150px;
  }

}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}